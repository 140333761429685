// import React, { useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import { db, storage } from "../../Context/firebase.config";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import { addDoc, collection } from "firebase/firestore";

// function AddSlider() {
//   const [image, setImage] = useState(null);
//   const [title, setTitle] = useState("");
//   const [mainTitle, setMainTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [progress, setProgress] = useState(0);
//   const [imageUrl, setImageUrl] = useState("");
//   const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

//   const handleImageChange = (e) => {
//     if (e.target.files[0]) {
//       const selectedImage = e.target.files[0];
//       setImage(selectedImage);

//       // Display image preview
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImageUrl(reader.result);
//       };
//       reader.readAsDataURL(selectedImage);
//     }
//   };

//   const handleUpload = () => {
//     setIsSaveButtonDisabled(true);
//     if (image) {
//       // Generate a unique name using a timestamp and the original image name
//       const timestamp = new Date().getTime();
//       const imageName = `${timestamp}_${image.name}`;

//       const storageRef = ref(storage, `slider/${imageName}`);
//       const uploadTask = uploadBytesResumable(storageRef, image);

//       uploadTask.on(
//         "state_changed",
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           setProgress(progress);
//         },
//         (error) => {
//           console.error(error.message);
//           toast.error("Error uploading image. Please try again.");
//         },
//         async () => {
//           try {
//             const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//             setImageUrl(downloadURL);
//             // Store the downloadURL, title, mainTitle, and description in Firestore
//             await storeImageDetailsInFirestore(downloadURL);
//           } catch (error) {
//             console.error("Error storing image details in Firestore: ", error);
//             toast.error("Error storing image details. Please try again.");
//           } finally {
//             setIsSaveButtonDisabled(false); // Enable the button after the upload process
//           }
//         }
//       );
//     }
//   };

//   const storeImageDetailsInFirestore = async (downloadURL) => {
//     try {
//       const docRef = await addDoc(collection(db, "slider"), {
//         imageUrl: downloadURL,
//         title,
//         mainTitle,
//         description,
//       });
//       console.log("Details stored in Firestore with ID: ", docRef.id);
//       toast.success("Details stored successfully!");
//       setTitle("");
//       setMainTitle("");
//       setDescription("");
//       setImageUrl("");
//       setProgress(0);
//       setImage(null);
//     } catch (error) {
//       console.error("Error storing details in Firestore: ", error);
//       toast.error("Error storing details. Please try again.");
//     }
//   };

//   const handleCancelImage = () => {
//     setImage(null);
//   };

//   return (
//     <div
//       className="border border-gray rounded p-4 mb-5"
//       style={{ width: "100%" }}
//     >
//       <h5 className="text-primary mb-4">Add Sliders</h5>
//       <div className="form-floating mb-3 container p-1">
//         <input
//           type="text"
//           className="form-control"
//           id="titleInput"
//           placeholder="Title"
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//         <label htmlFor="titleInput">Title</label>
//       </div>
//       <div className="form-floating mb-3 container p-1">
//         <input
//           type="text"
//           className="form-control"
//           id="mainTitleInput"
//           placeholder="Main title"
//           value={mainTitle}
//           onChange={(e) => setMainTitle(e.target.value)}
//         />
//         <label htmlFor="mainTitleInput">Main title</label>
//       </div>
//       <div className="form-floating mb-3 container p-1">
//         <textarea
//           className="form-control"
//           id="descriptionInput"
//           placeholder="Description"
//           rows="3"
//           value={description}
//           onChange={(e) => setDescription(e.target.value)}
//         />
//         <label htmlFor="descriptionInput">Description</label>
//       </div>

//       <div
//         className="border border-gray rounded p-4 mb-3"
//         style={{ width: "100%", display: "flex", alignItems: "center" }}
//       >
//         <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
//           <h6 className="text-primary mb-3">Add Image</h6>
//           <div className="input-group mb-3">
//             <label
//               htmlFor="inputGroupFile02"
//               className="form-control d-flex align-items-center"
//             >
//               Choose File
//               <input
//                 type="file"
//                 className="d-none"
//                 id="inputGroupFile02"
//                 onChange={handleImageChange}
//               />
//             </label>
//           </div>
//         </div>
//         {imageUrl && (
//           <div
//             className="mt-3"
//             style={{
//               position: "relative",
//               flex: "1",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <div
//               style={{
//                 border: "1px dashed #ccc",
//                 padding: "5px",
//                 position: "relative",
//               }}
//             >
//               <img src={imageUrl} alt="Uploaded" style={{ maxWidth: "20%" }} />
//               <button
//                 onClick={handleCancelImage}
//                 style={{
//                   background: "none",
//                   border: "none",
//                   color: "red",
//                   position: "absolute",
//                   top: "5px",
//                   right: "5px",
//                   cursor: "pointer",
//                 }}
//               >
//                 <FontAwesomeIcon icon={faTimes} />
//               </button>
//               {progress > 0 && <p>Upload Progress: {progress}%</p>}
//             </div>
//           </div>
//         )}
//       </div>

//       <div className="container">
//         <div className="row justify-content-end ">
//           <div className="col-auto  mx-auto">
//             <button
//               type="button"
//               className="btn btn-primary mt-2"
//               onClick={handleUpload}
//               disabled={isSaveButtonDisabled} // Disable the button based on the state
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// }

// export default AddSlider;
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


function AddDestinations() {
  const [showMessage, setShowMessage] = useState(false);
  const [description, setDescription] = useState("");

  const toggleMessage = (event) => {
    event.preventDefault(); // Prevent default scrolling behavior
    setShowMessage(true);

    // Display toast message for 3 seconds
    toast.success("Destination added successfully!", { autoClose: 3000 });
  };

  const [image1, setImage1] = useState(null);
  const [imageName1, setImageName1] = useState("");

  const [image2, setImage2] = useState(null);
  const [imageName2, setImageName2] = useState("");

  const [image3, setImage3] = useState(null);
  const [imageName3, setImageName3] = useState("");

  const [image4, setImage4] = useState(null);
  const [imageName4, setImageName4] = useState("");

  const [image5, setImage5] = useState(null);
  const [imageName5, setImageName5] = useState("");



  const handleImageChange1 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage1(reader.result);
      setImageName1(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage2(reader.result);
      setImageName2(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange3 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage3(reader.result);
      setImageName3(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange4 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage4(reader.result);
      setImageName4(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange5 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage5(reader.result);
      setImageName5(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage1 = () => {
    setImage1(null);
    setImageName1("");
  };

  const handleCancelImage2 = () => {
    setImage2(null);
    setImageName2("");
  };

  const handleCancelImage3 = () => {
    setImage3(null);
    setImageName3("");
  };
  const handleCancelImage4 = () => {
    setImage4(null);
    setImageName4("");
  };
  const handleCancelImage5 = () => {
    setImage5(null);
    setImageName5("");
  };


  return (
    <div
      className="border border-gray rounded p-4 mb-5"
      style={{ width: "100%" }}
    >
      <h5 className="text-primary mb-4">Add Destinations</h5>
      <div className="form-floating mb-3 container p-1">
        <input
          type="text"
          className="form-control"
          id="titleInput"
          placeholder="Destination"
        />
        <label htmlFor="titleInput">Destination</label>
      </div>
      <div className="form-floating mb-3 container p-1">
        <input
          type="text"
          className="form-control"
          id="mainTitleInput"
          placeholder="Category"
        />
        <label htmlFor="mainTitleInput">Category</label>
      </div>
      <div className="border border-gray rounded p-4 mb-3" style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
        <h6 className="text-primary mb-3">Add Blog Image</h6>
        <div className="input-group mb-3">
          <label htmlFor="inputGroupFile" className="form-control">
            Choose File
            <input
              type="file"
              className="d-none"
              id="inputGroupFile"
              onChange={handleImageChange1}
            />
          </label>
        </div>
      </div>
      {image1 && (
        <div className="mt-3" style={{ position: "relative", flex: "1", display: "flex", alignItems: "center" }}>
          <div style={{ border: "1px dashed #ccc", padding: "5px", position: "relative" }}>
            <img src={image1} alt="Uploaded" style={{ maxWidth: "20%" }} />
            <button onClick={handleCancelImage1} style={{ background: "none", border: "none", color: "red", position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {imageName1 && (
            <div style={{ marginLeft: "10px" }}>
              <p>{imageName1}</p>
            </div>
          )}
        </div>
      )}
    </div>

    <div className="border border-gray rounded p-4 mb-3" style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
        <h6 className="text-primary mb-3">Add Tour Photo 1</h6>
        <div className="input-group mb-3">
          <label htmlFor="inputGroupFile01" className="form-control">
            Choose File
            <input
              type="file"
              className="d-none"
              id="inputGroupFile01"
              onChange={handleImageChange2}
            />
          </label>
        </div>
      </div>
      {image2 && (
        <div className="mt-3" style={{ position: "relative", flex: "1", display: "flex", alignItems: "center" }}>
          <div style={{ border: "1px dashed #ccc", padding: "5px", position: "relative" }}>
            <img src={image2} alt="Uploaded" style={{ maxWidth: "20%" }} />
            <button onClick={handleCancelImage2} style={{ background: "none", border: "none", color: "red", position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {imageName2 && (
            <div style={{ marginLeft: "10px" }}>
              <p>{imageName2}</p>
            </div>
          )}
        </div>
      )}
    </div>
    <div className="border border-gray rounded p-4 mb-3" style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
        <h6 className="text-primary mb-3">Add Tour Photo 2</h6>
        <div className="input-group mb-3">
          <label htmlFor="inputGroupFile02" className="form-control">
            Choose File
            <input
              type="file"
              className="d-none"
              id="inputGroupFile02"
              onChange={handleImageChange3}
            />
          </label>
        </div>
      </div>
      {image3 && (
        <div className="mt-3" style={{ position: "relative", flex: "1", display: "flex", alignItems: "center" }}>
          <div style={{ border: "1px dashed #ccc", padding: "5px", position: "relative" }}>
            <img src={image3} alt="Uploaded" style={{ maxWidth: "20%" }} />
            <button onClick={handleCancelImage3} style={{ background: "none", border: "none", color: "red", position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {imageName3 && (
            <div style={{ marginLeft: "10px" }}>
              <p>{imageName3}</p>
            </div>
          )}
        </div>
      )}
    </div>

    <div className="border border-gray rounded p-4 mb-3" style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
        <h6 className="text-primary mb-3">Add Tour Photo 3</h6>
        <div className="input-group mb-3">
          <label htmlFor="inputGroupFile03" className="form-control">
            Choose File
            <input
              type="file"
              className="d-none"
              id="inputGroupFile03"
              onChange={handleImageChange4}
            />
          </label>
        </div>
      </div>
      {image4 && (
        <div className="mt-3" style={{ position: "relative", flex: "1", display: "flex", alignItems: "center" }}>
          <div style={{ border: "1px dashed #ccc", padding: "5px", position: "relative" }}>
            <img src={image4} alt="Uploaded" style={{ maxWidth: "20%" }} />
            <button onClick={handleCancelImage4} style={{ background: "none", border: "none", color: "red", position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {imageName4 && (
            <div style={{ marginLeft: "10px" }}>
              <p>{imageName4}</p>
            </div>
          )}
        </div>
      )}
    </div>

    <div className="border border-gray rounded p-4 mb-3" style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
        <h6 className="text-primary mb-3">Add Tour Photo 4</h6>
        <div className="input-group mb-3">
          <label htmlFor="inputGroupFile04" className="form-control">
            Choose File
            <input
              type="file"
              className="d-none"
              id="inputGroupFile04"
              onChange={handleImageChange5}
            />
          </label>
        </div>
      </div>
      {image5 && (
        <div className="mt-3" style={{ position: "relative", flex: "1", display: "flex", alignItems: "center" }}>
          <div style={{ border: "1px dashed #ccc", padding: "5px", position: "relative" }}>
            <img src={image5} alt="Uploaded" style={{ maxWidth: "20%" }} />
            <button onClick={handleCancelImage5} style={{ background: "none", border: "none", color: "red", position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {imageName5 && (
            <div style={{ marginLeft: "10px" }}>
              <p>{imageName5}</p>
            </div>
          )}
        </div>
      )}
    </div>





      <div className="container">
        <div className="row justify-content-end ">
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={toggleMessage}
            >
              Save
            </button>
          </div>
        </div>
     
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddDestinations;